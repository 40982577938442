import { $ } from "@utils/dom";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { SELECTOR } from "@ui/site-scroll/SiteScroll";
import SiteScroll from "../site-scroll/SiteScroll";

gsap.registerPlugin(ScrollTrigger);

class SiteTrigger {
  constructor() {
    this.initialized = false;
    this.sitescroll = SiteScroll;
  }

  get name() {
    return `SiteTrigger`;
  }

  init() {
    this.initialized = true;
    this.el = $(SELECTOR);

    console.log("init SITE TRIGGER");
    console.log(`scroll.y siteTrigger = ${this.sitescroll.y}`);
    ScrollTrigger.scrollerProxy(SELECTOR, {
      scrollTop(value) {
        if (this.sitescroll) {
          return arguments.length
            ? this.sitescroll.scrollTo(value, 0, 0)
            : this.sitescroll.scroll.instance.scroll.y;
        }
      },
      getBoundingClientRect() {
        return {
          top: 0,
          left: 0,
          width: window.innerWidth,
          height: window.innerHeight,
        };
      },

      pinType: $(SELECTOR).style.transform ? "transform" : "fixed",
    });
    // ScrollTrigger.addEventListener("refresh", () => this.sitescroll.scroll.update());
    ScrollTrigger.refresh(true);
  }

  start() {
    console.log("Start SITE TRIGGER");
  }

  destroy() {
    // this.el = null;
    // this.initialized = false;
  }
}

export default SiteTrigger;
