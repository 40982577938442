import Swiper, { Navigation, Pagination } from "swiper";

function swiperHero() {
  new Swiper(".my-swiper", {
    // pass modules here
    modules: [Navigation, Pagination],
    // ...
  });
}

export { swiperHero };
