import { gsap } from "gsap";

gsap.config({
  nullTargetWarn: false,
});

function scrollLineview() {
  document.querySelectorAll(".images").forEach(image => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: image,
        scroller: "[data-scroll-container]",
        scrub: true,
        end: "+=200%",
      },
    });

    tl.set(image, {
      autoAlpha: 0,
      scale: 0.8,
    })

      .fromTo(
        image,
        {
          autoAlpha: 0,
        },
        {
          autoAlpha: 1,
          scale: 1,
        },
      )

      .to(image, { autoAlpha: 0, scale: 1 });
  });
}

export { scrollLineview };
