export const SELECTOR = `[custom-cursor]`;

class Cursor {
  constructor() {
    // zCursor
    this.zCursor = document.createElement("div");
    this.zPointer = document.createElement("div");
    this.zFollower = document.createElement("div");

    this.init = this.init.bind(this);
    this.start = this.start.bind(this);
    this.destroy = this.destroy.bind(this);
    this.windowEnter = this.windowEnter.bind(this);
    this.windowOut = this.windowOut.bind(this);
    this.addCursor = this.addCursor.bind(this);
    this.pointer = this.pointer.bind(this);
    this.follower = this.follower.bind(this);
    this.linkEnter = this.linkEnter.bind(this);
    this.linkLeave = this.linkLeave.bind(this);
    this.slideEnter = this.slideEnter.bind(this);
    this.slideLeave = this.slideLeave.bind(this);
    this.projectEnter = this.projectEnter.bind(this);
    this.projectLeave = this.projectLeave.bind(this);
  }

  init() {
    this.zCursor.id = "zcursor-wrapper";
    this.zPointer.id = "zcursor-pointer";
    this.zFollower.id = "zcursor-follower";
  }

  start() {
    // Window enter
    document.body.addEventListener("mouseenter", this.windowEnter);
    // Window out
    document.body.addEventListener("mouseout", event => this.windowOut(event));
    // Cursor move (Pointer)
    document.addEventListener("pointermove", event => this.pointer(event));

    // Cursor move (Follower)
    document.addEventListener("pointermove", this.follower);

    // Enter | Leave Link <a>
    document.querySelectorAll("a").forEach(link => {
      link.addEventListener("mouseover", this.linkEnter);
      link.addEventListener("mouseout", this.linkLeave);
    });
    // Enter | Leave Slide
    document.querySelectorAll(".swiper").forEach(slide => {
      slide.addEventListener("mouseover", this.slideEnter);
      slide.addEventListener("mouseout", this.slideLeave);
    });
    // Enter | Leave Project
    document.querySelectorAll(".project").forEach(project => {
      project.addEventListener("mouseover", this.projectEnter);
      project.addEventListener("mouseout", this.projectLeave);
    });
  }

  destroy() {
    // Window enter
    document.body.removeEventListener("mouseenter", this.windowEnter);
    // Window out
    document.body.removeEventListener("mouseout", event =>
      this.windowOut(event),
    );
    // Cursor move (Pointer)
    document.removeEventListener("pointermove", event => this.pointer(event));

    // Cursor move (Follower)
    document.removeEventListener("pointermove", this.follower);

    // Enter | Leave Link <a>
    document.querySelectorAll("a").forEach(link => {
      link.removeEventListener("mouseover", this.linkEnter);
      link.removeEventListener("mouseout", this.linkLeave);
    });
    // Enter | Leave Slide
    document.querySelectorAll(".swiper").forEach(slide => {
      slide.removeEventListener("mouseover", this.slideEnter);
      slide.removeEventListener("mouseout", this.slideLeave);
    });
    // Enter | Leave Project
    document.querySelectorAll(".project").forEach(project => {
      project.removeEventListener("mouseover", this.projectEnter);
      project.removeEventListener("mouseout", this.projectLeave);
    });
  }

  windowEnter() {
    this.addCursor();
  }

  windowOut(event) {
    if (!event.relatedTarget && !event.toElement) {
      this.zCursor.remove();
    }
  }

  addCursor() {
    this.zCursor.appendChild(this.zPointer);
    this.zCursor.appendChild(this.zFollower);
    document.body.appendChild(this.zCursor);
  }

  pointer(e) {
    this.zPointer.style.WebkitTransform = `matrix(1, 0, 0, 1,${e.clientX},${e.clientY})`;
    this.zPointer.style.msTransform = `matrix(1, 0, 0, 1,${e.clientX},${e.clientY})`;
    this.zPointer.style.transform = `matrix(1, 0, 0, 1,${e.clientX},${e.clientY})`;
  }

  follower(e) {
    this.zFollower.style.WebkitTransform = `matrix(1, 0, 0, 1,${e.clientX},${e.clientY})`;
    this.zFollower.style.msTransform = `matrix(1, 0, 0, 1,${e.clientX},${e.clientY})`;
    this.zFollower.style.transform = `matrix(1, 0, 0, 1,${e.clientX},${e.clientY})`;
  }

  linkEnter() {
    this.zCursor.classList.add("active");
  }

  linkLeave() {
    this.zCursor.classList.remove("active");
  }

  slideEnter() {
    this.zCursor.classList.add("slide");
  }

  slideLeave() {
    this.zCursor.classList.remove("slide");
  }

  projectEnter() {
    this.zCursor.classList.add("project");
  }

  projectLeave() {
    this.zCursor.classList.remove("project");
  }
}

export default Cursor;
